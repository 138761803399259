import * as React from "react";

import { Layout } from "../components/layout";

const IndexPage = () => (
  <Layout>
    <p>
      Félicitations pour votre achat, vous avez du gout ! Vous allez recevoir
      très vite une confirmation par mail.
    </p>
  </Layout>
);

export default IndexPage;
